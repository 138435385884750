import React, { useEffect, useRef, useState, useMemo, Suspense } from "react";
import dynapt from "../../assests/chatlogo.png";
import { useGLTF, useAnimations, Environment } from "@react-three/drei";
import createAnimation from "../converter";
import { Canvas, useFrame } from "@react-three/fiber";
import * as THREE from 'three';
import blinkData from '../blendDataBlink.json';
import ChatWithMicComponent from "./chat";
import { useNavigate } from "react-router-dom";
import { useLocation, useParams } from 'react-router-dom';
const _ = require('lodash');

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ChatTextBox = () => {
    const query = useQuery();
    const { id } = useParams();
    const [avatarSelected, setAvatarSelected] = useState();
    const avatarUrl = `output_${avatarSelected}.glb`;
    const videoRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [isRecording, setIsRecording] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [error, setError] = useState("");


    let navigate = useNavigate();

    const [clips, setClips] = useState();
    const [botConfigurationsDetails, setbotConfigurationsDetails] = useState({});
    const [ChatTextBoxtoggle, setChatTextBoxtoggle] = useState(true);
    const morphTargets = [];
    const morphTargetsName = [];
    const [displayOption, setDisplayOption] = useState(null);

    const count = useRef(0);

    useEffect(() => {
        if (count.current === 0) {
            const GUID = id;
            fetchBotDataById(GUID);
            count.current += 1;
        }
    }, [id]);

    useEffect(() => {
        const link = document.createElement('link');
        link.href = 'https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css';
        link.rel = 'stylesheet';
        link.id = 'bootstrap-css';
        document.head.appendChild(link);

        return () => {
            document.head.removeChild(document.getElementById('bootstrap-css'));
        };
    }, []);

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth;
            if ((windowSize.width <= 425 && newWidth > 425) || (windowSize.width > 425 && newWidth <= 425)) {
                setWindowSize({
                    width: newWidth,
                    height: window.innerHeight
                });
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [windowSize.width]);

    const speechSynthesis = window.speechSynthesis;
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();
    recognition.lang = 'en-IN';

    const playedRef = useRef(true);
    const setRef = () => {
        playedRef.current = false;
    };

    const fetchBotDataById = async (id) => {
        try {
            await fetch(`https://dynaptmax-backend-fastapi-wtnegfwvgq-el.a.run.app/bot_studio/get_bot_configuration_details_by_Guid?Guid=${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    setbotConfigurationsDetails(data[0]);
                    if (data[0].AvatarName === "Avaatar1") {
                        setAvatarSelected('2');
                    } else if (data[0].AvatarName === "Avaatar2") {
                        setAvatarSelected('3');
                    } else if (data[0].AvatarName === "Avaatar3") {
                        setAvatarSelected('4');
                    } else if (data[0].AvatarName === "Avaatar") {
                        setAvatarSelected('1');
                    }

                    if (data[0] && data[0].BotName) {
                        document.title = data[0].BotName;
                    }

                    if (data[0] && data[0].LogoURL) {
                        const favicon = document.getElementById('favicon');
                        if (favicon) {
                            favicon.href = data[0].LogoURL;
                        } else {
                            const link = document.createElement('link');
                            link.id = 'favicon';
                            link.rel = 'icon';
                            link.href = data[0].LogoURL;
                            document.head.appendChild(link);
                        }
                    }

                    if (data[0] && data[0].ChatTypeOptions) {

                        const AllOptions = data[0].ChatTypeOptions;
                        console.log("AllOptions.length", AllOptions.length)

                        if (AllOptions.length === 4) {
                            // console.log("44444444444444")
                            setDisplayOption('ChatAndAvatar');
                        } else if (AllOptions.length === 1) {
                            // console.log("111111111111")
                            if (AllOptions[0].code === "ChatAvatarAndVideoRecording") {
                                startRecording();
                            } else {
                                setDisplayOption(AllOptions[0].code)
                            }
                        } else {
                            console.log("elseeeeeeeeeeeeeeeeeeeeeee")
                            // Function to find an option code by name
                            const findOptionCode = (name) => {
                                const option = AllOptions.find(opt => opt.name === name);
                                return option ? option.code : null;
                            };

                            // Check for options in priority order
                            const chatAndAvatarCode = findOptionCode("Chat And Avatar");
                            const avatarOnlyCode = findOptionCode("Avatar Only");
                            const chatAndAudioCode = findOptionCode("Chat and Audio");

                            if (chatAndAvatarCode) {
                                console.log("chatAndAvatarCode", chatAndAvatarCode)
                                setDisplayOption(chatAndAvatarCode);
                            } else if (avatarOnlyCode) {
                                // console.log("avatarOnlyCode",avatarOnlyCode)
                                setDisplayOption(avatarOnlyCode);
                            } else if (chatAndAudioCode) {
                                // console.log("chatAndAudioCode",chatAndAudioCode)
                                setDisplayOption(chatAndAudioCode);
                            } else {
                                // Do nothing if none of the prioritized options are found
                                console.log("No prioritized ChatTypeOptions found");
                            }

                        }
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const startRecording = async () => {
        try {
            // Request access to the user's camera and microphone
            const stream = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true,
            });

            // Display the live video preview
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                videoRef.current.play();
            }

            // Initialize the MediaRecorder
            const mediaRecorder = new MediaRecorder(stream);
            mediaRecorderRef.current = mediaRecorder;

            // Collect video data chunks
            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    setRecordedChunks((prev) => [...prev, event.data]);
                }
            };

            // Start recording
            mediaRecorder.start();
            setIsRecording(true);
            setError(""); // Clear any previous errors
            console.log("Recording started...");
        } catch (err) {
            console.error("Error accessing media devices:", err.message);
            if (err.name === "NotAllowedError") {
                setError("Permission denied. Please allow access to the camera and microphone.");
            } else if (err.name === "NotFoundError") {
                setError("No camera or microphone found. Please connect a device.");
            } else {
                setError("An error occurred while accessing media devices.");
            }
        }
    };
    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            // Stop the MediaRecorder
            mediaRecorderRef.current.stop();
            setIsRecording(false);
            console.log("Recording stopped.");

            // Stop the video preview
            if (videoRef?.current) {
                const stream = videoRef.current.srcObject;
                const tracks = stream?.getTracks();
                tracks?.forEach((track) => track.stop());
                videoRef.current.srcObject = null;
            }
            // Wait for a short delay to ensure chunks are available
            setTimeout(() => {
                uploadRecording();
            },20000);
        }
    };
    const uploadRecording = async () => {
        console.log("uploadRecording================================================");
        console.log("recordedChunks================================================",recordedChunks);
        if (recordedChunks.length === 0) return;
        const accessToken = await getAccessToken();

        // Create a Blob from recorded chunks
        const blob = new Blob(recordedChunks, { type: "video/webm" });

        // Generate the filename with full timestamp (yyyy-mm-dd_HH-MM-SS)
        const now = new Date();
        const dateStr = now.toISOString().split("T")[0]; // yyyy-mm-dd
        const timeStr = now.toTimeString().split(" ")[0].replace(/:/g, "-"); // HH-MM-SS
        const fileName = `${dateStr}_${timeStr}_Video.webm`;

        // Define the full path in the bucket
        const filePath = `${id}/${fileName}`;

        try {
            const response = await fetch(
                `https://storage.googleapis.com/upload/storage/v1/b/shubham1075/o?uploadType=media&name=${encodeURIComponent(filePath)}`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "video/webm",
                    },
                    body: blob,
                }
            );

            const result = await response.json();
            if (response.ok) {
                console.log("Upload successful:", result);
                // alert("Video uploaded successfully!");
            } else {
                console.error("Upload failed:", result);
                setError("Upload failed. Please try again.");
            }
        } catch (error) {
            console.error("Error uploading file:", error);
            setError("An error occurred while uploading.");
        }
    };
    const getAccessToken = async () => {
        try {
            const response = await fetch(
                "https://dynaptmax-backend-fastapi-wtnegfwvgq-el.a.run.app/helper_routers/get_google_auth_access_token",
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                    },
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.log("error while generating access token ");
        }
    };

    const Avatar = (props) => {
        const group = useRef();
        const { nodes, materials, animations, scene } = useGLTF(avatarUrl);
        const { actions, names } = useAnimations(animations, group);

        const mixer = useMemo(() => new THREE.AnimationMixer(scene));
        const mixer2 = useMemo(() => new THREE.AnimationMixer(scene));

        scene.traverse(node => {
            if (node.morphTargetDictionary) {
                morphTargetsName.push(node.name);
                morphTargets.push(node.morphTargetDictionary);
            }
        });

        const BlinkClips = [];

        for (let i = 0; i < Object.keys(morphTargets).length; i++) {
            BlinkClips.push(createAnimation(blinkData, morphTargets[i], morphTargetsName[i]));
        }

        useEffect(() => {
            actions[names[0]].play();

            _.each(BlinkClips, clip => {
                let clipAction = mixer2.clipAction(clip);
                clipAction.play();
            });

        }, [actions, names, BlinkClips, mixer2]);

        useFrame((state, delta) => {
            mixer.update(delta);
            mixer2.update(delta);
        });

        useEffect(() => {
            if (!playedRef.current) {
                setTimeout(() => {
                    _.each(clips, clip => {
                        let clipAction = mixer.clipAction(clip);
                        clipAction.setLoop(THREE.LoopOnce);
                        clipAction.play();
                    });
                }, 800);
            }
        }, [clips, mixer]);

        mixer.addEventListener('finished', function (e) {
            playedRef.current = true;
        });

        return (
            <Suspense fallback={null}>
                <group ref={group} {...props} dispose={null}>
                    <group name="Scene">
                        <group name="Armature">
                            <skinnedMesh
                                name="avaturn_hair_0"
                                geometry={nodes.avaturn_hair_0.geometry}
                                material={materials.avaturn_hair_0_material}
                                skeleton={nodes.avaturn_hair_0.skeleton}
                            />
                            <skinnedMesh
                                name="avaturn_hair_1"
                                geometry={nodes.avaturn_hair_1.geometry}
                                material={materials.avaturn_hair_1_material}
                                skeleton={nodes.avaturn_hair_1.skeleton}
                            />
                            <skinnedMesh
                                name="avaturn_look_0"
                                geometry={nodes.avaturn_look_0.geometry}
                                material={materials.avaturn_look_0_material}
                                skeleton={nodes.avaturn_look_0.skeleton}
                            />
                            <skinnedMesh
                                name="avaturn_shoes_0"
                                geometry={nodes.avaturn_shoes_0.geometry}
                                material={materials.avaturn_shoes_0_material}
                                skeleton={nodes.avaturn_shoes_0.skeleton}
                            />
                            <skinnedMesh
                                name="Body_Mesh"
                                geometry={nodes.Body_Mesh.geometry}
                                material={materials.Body}
                                skeleton={nodes.Body_Mesh.skeleton}
                            />
                            <skinnedMesh
                                name="Eye_Mesh"
                                geometry={nodes.Eye_Mesh.geometry}
                                material={materials.Eyes}
                                skeleton={nodes.Eye_Mesh.skeleton}
                                morphTargetDictionary={nodes.Eye_Mesh.morphTargetDictionary}
                                morphTargetInfluences={nodes.Eye_Mesh.morphTargetInfluences}
                            />
                            <skinnedMesh
                                name="EyeAO_Mesh"
                                geometry={nodes.EyeAO_Mesh.geometry}
                                material={materials.EyeAO}
                                skeleton={nodes.EyeAO_Mesh.skeleton}
                                morphTargetDictionary={nodes.EyeAO_Mesh.morphTargetDictionary}
                                morphTargetInfluences={nodes.EyeAO_Mesh.morphTargetInfluences}
                            />
                            <skinnedMesh
                                name="Eyelash_Mesh"
                                geometry={nodes.Eyelash_Mesh.geometry}
                                material={materials.Eyelash}
                                skeleton={nodes.Eyelash_Mesh.skeleton}
                                morphTargetDictionary={nodes.Eyelash_Mesh.morphTargetDictionary}
                                morphTargetInfluences={nodes.Eyelash_Mesh.morphTargetInfluences}
                            />
                            <skinnedMesh
                                name="Head_Mesh"
                                geometry={nodes.Head_Mesh.geometry}
                                material={materials.Head}
                                skeleton={nodes.Head_Mesh.skeleton}
                                morphTargetDictionary={nodes.Head_Mesh.morphTargetDictionary}
                                morphTargetInfluences={nodes.Head_Mesh.morphTargetInfluences}
                            />
                            <skinnedMesh
                                name="Teeth_Mesh"
                                geometry={nodes.Teeth_Mesh.geometry}
                                material={materials.Teeth}
                                skeleton={nodes.Teeth_Mesh.skeleton}
                                morphTargetDictionary={nodes.Teeth_Mesh.morphTargetDictionary}
                                morphTargetInfluences={nodes.Teeth_Mesh.morphTargetInfluences}
                            />
                            <skinnedMesh
                                name="Tongue_Mesh"
                                geometry={nodes.Tongue_Mesh.geometry}
                                material={materials.Teeth}
                                skeleton={nodes.Tongue_Mesh.skeleton}
                                morphTargetDictionary={nodes.Tongue_Mesh.morphTargetDictionary}
                                morphTargetInfluences={nodes.Tongue_Mesh.morphTargetInfluences}
                            />
                            <primitive object={nodes.Hips} />
                        </group>
                    </group>
                </group>
            </Suspense>
        );
    };

    useGLTF.preload(avatarUrl);

    return (
        <div className={`container-fluid ${windowSize.width > 425 ? 'p-4' : ''}`}>
            <div className={`row align-items-center justify-content-between mb-2 ${windowSize.width <= 425 ? 'mt-2' : ''}`}>
                <div className="col-6 col-sm-1 col-md-3 col-lg-2 text-start" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: '50px', width: '100%', overflow: 'hidden' }}>
                    <img src={botConfigurationsDetails.LogoURL == null ? dynapt : botConfigurationsDetails.LogoURL} alt="dynapt" className="img-fluid" style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }} />
                </div>
                {windowSize.width > 425 && (
                    <div className={'col-sm-6 col-md-7 col-lg-6 text-center'}>
                        <h2 style={{ color: `#${botConfigurationsDetails.ThemeColor}`, fontSize: '24px', fontWeight: 'bold' }} >Welcome to {botConfigurationsDetails.BotName} Support</h2>
                        {error && <p style={styles.error}>{error}</p>}
                    </div>
                )}
                <video
                    ref={videoRef}
                    autoPlay
                    muted
                    style={styles.video}
                ></video>
                <div className={'col-6 col-sm-1 col-md-2 col-lg-2 text-right'}>
                </div>
            </div>
            {windowSize.width > 425 && (
                <div className="row">
                    {displayOption === 'ChatAndAudio' || displayOption === 'ChatOnly' ?
                        null
                        : <div className={`col-lg-${ChatTextBoxtoggle ? '6' : '12'} col-md-6 col-sm-12`} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Canvas shadows camera={{ position: [0, 0.7, 10], fov: 42 }} dpr={2} onCreated={(ctx) => {
                                ctx.gl.physicallyCorrectLights = true;
                            }} style={{ width: '100%', height: '100%' }}>
                                {avatarSelected && <Avatar position={[0, -5, 6]} scale={3.5} />}
                                <Environment preset="sunset" />
                            </Canvas>
                        </div>}

                    <ChatWithMicComponent
                        userEmail={"shubhamsharma@dynapt.co.in"}
                        clips={clips}
                        setClips={setClips}
                        morphTargets={morphTargets}
                        morphTargetsName={morphTargetsName}
                        ChatTextBoxtoggle={ChatTextBoxtoggle}
                        setChatTextBoxtoggle={setChatTextBoxtoggle}
                        setRef={setRef}
                        recognition={recognition}
                        speechSynthesis={speechSynthesis}
                        displayOption={displayOption}
                        setDisplayOption={setDisplayOption}
                        botConfigurationsDetails={botConfigurationsDetails}
                        startRecording={startRecording}
                        stopRecording={stopRecording}
                    />
                </div>)}
            {windowSize.width <= 425 && (
                <div className="row">
                    {displayOption === 'ChatAndAudio' || displayOption === 'ChatOnly' ? null :
                        <div className={`col-lg-${ChatTextBoxtoggle ? '6' : '12'} col-md-6 col-sm-12`} style={{ display: "flex", justifyContent: "center", alignItems: "center", height: ChatTextBoxtoggle ? '37vh' : '75vh' }}>
                            <Canvas shadows camera={{ position: [0, 0.7, 10], fov: 42 }} dpr={2} onCreated={(ctx) => {
                                ctx.gl.physicallyCorrectLights = true;
                            }} style={{ width: '100%', height: '100%', border: 'none', boxShadow: '0px 0px 5px rgba(0,0,0,0.3)', borderRadius: '20px' }}>
                                {avatarSelected && (
                                    !ChatTextBoxtoggle ?
                                        <Avatar position={[0, -5, 6]} scale={3.5} /> :
                                        <Avatar position={[0, -9.4, 6]} scale={6} />
                                )}
                                <Environment preset="sunset" />
                            </Canvas>
                        </div>
                    }
                    <ChatWithMicComponent
                        userEmail={"shubhamsharma@dynapt.co.in"}
                        clips={clips}
                        setClips={setClips}
                        morphTargets={morphTargets}
                        morphTargetsName={morphTargetsName}
                        ChatTextBoxtoggle={ChatTextBoxtoggle}
                        setChatTextBoxtoggle={setChatTextBoxtoggle}
                        setRef={setRef}
                        recognition={recognition}
                        speechSynthesis={speechSynthesis}
                        displayOption={displayOption}
                        setDisplayOption={setDisplayOption}
                        botConfigurationsDetails={botConfigurationsDetails}
                        startRecording={startRecording}
                        stopRecording={stopRecording}
                    />
                </div>
            )}
        </div>
    );
};

const styles = {
    video: {
        width: "100%",
        maxWidth: "100px",
        height: "100px",
        // border: "1px solid #ccc",
        // borderRadius: "8px",
    },
    error: {
        color: "red",
        fontSize: "14px",
        marginBottom: "10px",
    },
};

export default ChatTextBox;
